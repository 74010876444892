:root {
	--base-12: 0, 0, 0;
	--base-11: 17, 17, 17;
	--base-10: 33, 33, 33;
	--base-09: 44, 44, 44;
	--base-08: 55, 55, 55;
	--base-07: 75, 75, 75;
	--base-06: 95, 95, 95;
	--base-05: 115, 115, 115;
	--base-04: 135, 135, 135;
	--base-03: 195, 195, 195;
	--base-02: 235, 235, 235;
	--base-01: 255, 255, 255;
	--primary-accent-red: 190, 48, 48;
	--primary-accent-red-light: 235, 69, 66;
	--primary-accent-red-dark: 135, 0, 9;
	--primary-accent-grey: 55, 55, 55;
	--primary-accent-grey-light: 75, 75, 75;
	--primary-accent-grey-dark: 33, 33, 33;
	--primary-accent-gold: 238, 171, 0;
	--primary-accent-gold-light: 247, 213, 128;
	--primary-accent-gold-dark: 214, 154, 0;
	--primary: var(--primary-accent-red);
	--primary-light: var(--primary-accent-red-light);
	--primary-dark: var(--primary-accent-red-dark);
	--secondary: var(--primary-accent-red);
	--secondary-light: var(--primary-accent-red-light);
	--secondary-dark: var(--primary-accent-red-dark);
	--tertiary: var(--base-01);
	--tertiary-light: var(--font-light);
	--tertiary-dark: var(--font-dark);

	// Font Colors
	--font-brand-01: var(--primary-accent-red-light);
	--font-dark: var(--base-10);
	--font-light: var(--base-01);
	--font-supportive: var(--base-03);
	--font-disabled: 183, 184, 188;

	// System Colors
	--error: 233, 38, 54;
	--success: 0, 199, 106;
	--info: 0, 130, 235;
	--pending: 249, 175, 65;
	--purple: 167, 100, 255;

	// Extras
	--overlay: 17, 17, 17;

	// Type Vars
	--display: normal normal 500 7rem 'Oswald', sans-serif;
	--display-2: italic normal 500 5rem 'Oswald', sans-serif;
	--display-3: normal normal 500 3.6rem 'Oswald', sans-serif;
	--h1: normal normal 500 2.8rem/1.285 'Oswald', sans-serif;
	--h2: normal normal 500 2.2rem/1.335 'Oswald', sans-serif;
	--h3: normal normal 500 1.9rem/1.265 'Oswald', sans-serif;
	--h4-bold: normal normal 500 1.7rem/1.412 'Oswald', sans-serif;
	--h4-regular: normal normal 400 1.7rem/1.412 'Nunito Sans', sans-serif;
	--heading-5: normal normal 500 1.6rem/1.4375 'Oswald', sans-serif;
	--label-regular: normal normal 400 1.5rem/1.335 'Nunito Sans', sans-serif;
	--label-bold: normal normal 700 1.5rem/1.335 'Nunito Sans', sans-serif;
	--caption: normal normal 400 1.2rem/1.335 'Nunito Sans', sans-serif;
	--caption-bold: normal normal 700 1.2rem/1.335 'Nunito Sans', sans-serif;
	--small: normal normal 400 1.1rem/1.365 'Nunito Sans', sans-serif;
	--small-bold: normal normal 700 1.1rem/1.365 'Nunito Sans', sans-serif;
	--brand-text-transform: uppercase;

	// Font Changes 4
	// --top-right-corner-link: defaults to --label-regular;
	--top-right-corner-link-padding: 0;
	//--product-icon-text: defaults to --label-regular;
	--footer-links: var(--label-bold);
	//--footer-flag-language-text: defaults to --label-regular;
	--custom-legalny-bukmacher-logo-color: var(--base-04);

	// Progress Meter
	--risk-level-base: var(--primary-accent-grey);
	--risk-level-0: var(--primary-accent-grey);
	--risk-level-1: var(--success);
	--risk-level-2: var(--success);
	--risk-level-3: var(--pending);
	--risk-level-4: var(--error);

	// Notification Inbox
	// Notification counter
	--unread-message-counter-bg-color: var(--primary-accent-gold);
	--unread-message-counter-text-color: var(--font-dark);

	// Notification Header
	--my-notifications-header-bg-color: var(--base-10);
	--my-notifications-back-button-filter: var(--base-01);
	--my-notifications-heading-color: var(--base-01);
	--my-notifications-mark-all-read-color: var(--primary-accent-red-light);
	--my-notifications-back-button-color: rgba(var(--base-01), 1);

	// Notification: Unread message
	--unread-message-notifier-color: var(--primary-accent-gold);
	--unread-message-title-color: var(--base-01);
	--unread-message-subtitle-color: var(--font-supportive);
	--unread-message-date-color: var(--font-supportive);

	// Notification: Read message
	--read-message-color: var(--font-supportive);
	--read-message-title-color: var(--font-supportive);

	// Notification: No Data
	--no-notifications-icon-color: var(--base-01);
	--no-notifications-heading-color: var(--base-01);
	--no-notifications-subheading-color: var(--font-supportive);

	// Notification: Full screen popup
	--notification-popup-bg: var(--base-10);
	--notification-popup-title: var(--font-light);
	--notification-popup-subtitle: var(--font-light);
	--notification-popup-close-icon: var(--base-01);
	--notification-popup-button-bg: var(--primary-accent-red);
	--notification-popup-button-text: var(--font-light);
	--notification-popup-blur-bg: var(--base-10);
	--notification-popup-dot-active: var(--base-01);
	--notification-popup-dot: var(--base-07);

	// Web-push
	--web-push-popup-bg: var(--base-10);
	--web-push-popup-title: var(--font-light);
	--web-push-popup-subtitle: var(--font-light);
	--web-push-popup-button-bg: var(--primary-accent-red-light);
	--web-push-popup-button-text: var(--primary-accent-red-light);

	// Source Of Wealth
	--source-of-wealth-help-text: var(--font-light);
	--source-of-wealth-form-bg: var(--base-08);
	--source-of-wealth-form-text: var(--font-light);
	--source-of-wealth-dropdown-bg: var(--base-10);
	--source-of-wealth-dropdown-border: var(--base-08);

	//embed link
	--embed-link-color: var(--primary-accent-red-light);

	// Landing Page
	--landing-default-bg-color: rgba(var(--primary-accent-red), 1);
	--login-btn-bg: rgba(var(--base-01), 1);
	--login-btn-text: rgba(var(--font-dark), 1);
	// --landing-page-logo: defaults to --base-02
	--landing-page-subtitle: var(--h4-regular);
	--landing-page-title: rgba(var(--font-light), 1);
	--product-icon-bg: rgba(var(--base-01), 1);
	--product-icon: rgba(var(--base-12), 1);
	--product-icon-label: rgba(var(--font-light), 1);
	--terms-and-condition_bg: rgba(var(--overlay), 0.8);
	--bonus-terms-text: rgba(var(--font-light), 1);
	--bonus-terms-link: rgba(var(--font-light), 1);
	--btn-dots: rgba(var(--primary-accent-red-light), 1);
	--welcome-popup-bg: rgba(var(--primary-accent-red), 1);
	--welcome-popup-text-color: rgba(var(--font-light), 1);

	// Head
	--head-bg: rgba(var(--primary-accent-red), 1);
	--page-head-logo-height: 32px;
	// --page-head-logo: defaults to --base-02
	// --page-head-title: defaults to --base-02
	// --account-text:  defaults to --base-02
	// --account-icon:  defaults to --base-02
	--page-head-link: rgba(var(--font-light), 1);
	--page-head-icon: rgba(var(--font-light), 1);
	--balance-circle: rgba(var(--primary-accent-red-dark), 1);
	--balance-icon-wrapper: rgba(var(--base-01), 1);
	--balance-icon-wrapper-basic: rgba(var(--base-05), 1);
	--balance-shop-animate-points-wrapper: rgba(var(--primary-accent-green), 1);
	// --balance-text:  defaults to --base-02
	// --balance-icons: defaults to --base-02
	// --page-head-link: defaults to --base-02
	--balance-header-icon: rgba(var(--primary-accent-red), 1);
	--quick-deposit-icon-color: rgba(var(--base-12), 1);
	--balance-fill-icon-color: rgba(var(--base-01), 1);
	--hamburger-menu: rgba(var(--base-02), 1);

	// Chip
	--balance-chip-circle: rgba(var(--primary-accent-red-dark), 1);
	--balance-icon-chip-wrapper: rgba(var(--primary-accent-red), 1);
	--balance-chip-icon: rgba(var(--font-light), 1);
	--balance-chip-text: rgba(var(--font-light), 1);

	// Buttons
	--button-primary-bg: rgba(var(--primary-accent-red), 1);
	--button-primary-text: rgba(var(--base-02), 1);
	// --button-secondary-bg: defaults to --base-08
	// --button-secondary-text: defaults to --base-02
	--button-wrapper-bg: rgba(var(--base-10), 1);
	--button-wrapper-bg-light: rgba(var(--base-01), 1);
	--button-register: rgba(var(--base-01), 1);
	--button-register-text: rgba(var(--font-dark), 1);
	--button-pag: rgba(var(--base-05), 1);
	--support-button: rgba(var(--base-01), 1);
	--support-button-content: rgba(var(--font-dark), 1);
	// --button-border-radius: defaults to 3px
	--button-disabled-bg: rgba(var(--base-08), 1);
	--button-disabled-text: rgba(var(--base-04), 1);
	// Commented due to uncertainty.
	// --button-success-bg: rgba(#3099ea, 1);
	// --button-success-color: rgba(var(--base-02), 1);
	--moje-id-btn-txt-color: rgba(var(--primary-accent-red-light), 1);

	// Badges
	--badge-primary-bg: rgba(var(--primary-accent-green), 1);
	--badge-primary-text: rgba(var(--base-02), 1);
	--badge-new-bg: rgba(var(--primary-accent-gold), 1);
	--badge-new-text: rgba(var(--font-dark), 1);
	--badge-dark-bg: rgba(var(--base-12), 1);
	--badge-dark-text: rgba(var(--base-01), 1);
	--badge-secondary-bg: rgba(var(--primary-accent-gold), 1);
	--badge-secondary-text: rgba(var(--base-10), 1);

	// Select
	--select-border-bottom-color: rgba(var(--primary-accent-grey-dark), 1);
	--select-selected-option-color: rgba(var(--base-10), 1);
	--select-option-color: rgba(var(--base-10), 1);
	--select-option-bg-color: rgba(var(--base-01), 1);
	--select-legend-color: rgba(var(--base-10), 1);
	--select-arrow-down-color: rgba(var(--primary-accent-green), 1);

	// Pill

	--pill-bg: rgba(var(--base-09), 1);
	--pill-label-color: rgba(var(--base-02), 1);
	--pill-checked-bg: rgba(var(--primary), 1);
	--pill-checked-label-color: rgba(var(--base-02), 1);

	// Link List
	--link-list: rgba(var(--base-08), 1);
	--list-detail-value: rgba(var(--font-light), 1);
	--list-text-color: rgba(var(--font-light), 1);

	// Borders
	--list-border-bottom: rgba(var(--primary-accent-grey-dark), 1);

	// Filters 12
	--filter-bg: rgba(var(--base-10), 1);
	--filter-border-primary: 1px solid rgba(var(--base-07), 1);
	--filter-bg-secondary: rgba(var(--base-10), 1);
	--filter-border-secondary: 1px solid rgba(var(--primary-accent-red-dark), 1);
	--filter-idle: rgba(var(--base-01), 1);
	--filter-idle-secondary: rgba(var(--base-1), 1);
	--filter-active: rgba(var(--base-01), 1);
	--filter-active-secondary: rgba(var(--base-01), 1);
	--filter-bar-active: rgba(var(--base-01), 1);
	--filter-bar-active-secondary: rgba(var(--base-01), 1);
	--filter-bar-inactive: rgba(var(--base-07), 1);
	--filter-hover: rgba(var(--primary-accent-red-light), 1);
	--filter-hover-secondary: rgba(var(--primary-accent-red-light), 1);
	--filter-support-links: rgba(var(--font-light), 1);
	--filter-bar-bottom-border: rgba(var(--primary-accent-grey-light), 1);

	// New Search
	--search-bg: rgba(var(--primary-accent-red-dark), 1);
	// --search-text defaults to --base-02
	// --search-icon: defaults to --base-02
	--search-bg-large: rgba(var(--primary-accent-red-dark), 1);
	--search-text-large: rgba(var(--base-02), 1);
	--search-icon-large: rgba(var(--base-02), 1);

	// Custom checkboxes
	--custom-checkbox-border-color: var(--base-01);
	--custom-checked-checkbox-bg: var(--primary-accent-red-light);
	--custom-checked-checkbox-border-color: var(--primary-accent-red-light);
	--custom-checked-checkmark-color: var(--base-12);
	--custom-checked-disabled-checkbox-bg: var(--base-04);
	--custom-checked-disabled-checkbox-border-color: var(--base-04);
	--custom-checked-disabled-checkbox-checkmark: var(--base-12);

	// Custom radiobuttons
	--custom-radio-border: var(--base-01);
	--custom-checked-radio-bg: var(--primary-accent-red-light);
	--custom-checked-radio-border-color: var(--primary-accent-red-light);
	--custom-disabled-radio-border-color: var(--base-04);
	--custom-checked-disabled-radio-bg: var(--base-04);
	--custom-checked-disabled-radio-border-color: var(--base-06);

	// Section Head
	--main-icon: rgba(var(--base-01), 1);
	--main-title: rgba(var(--font-light), 1);
	--main-subtitle: rgba(var(--font-supportive), 1);
	--inverted-main-icon: rgba(var(--base-02), 1);
	--inverted-main-title: rgba(var(--base-02), 1);
	--inverted-main-subtitle: rgba(var(--base-02), 1);
	--section-head-main-icon: rgba(var(--base-01), 1);
	--deposit-confirmation-icon-color: rgba(var(--primary-accent-red), 1);

	// Game Tags
	--hot-bg: rgba(var(--primary-accent-red), 1);
	--new-bg: rgba(var(--primary-accent-green), 1);
	// --new-text: defaults to --base-02

	// Sign In
	--sign-in-box: rgba(var(--primary-accent-grey-dark), 1);
	--sign-in-box-border: rgba(var(--primary-accent-grey-light), 1);

	// Tabs
	--product-tab: rgba(var(--base-08), 1);
	--product-tab-content: rgba(var(--base-03), 1);
	//--product-tab-border-top: defaults to --base-09;
	--product-tab-selected: rgba(var(--primary-accent-green), 1);

	// Sidebar
	--sidebar-bg: rgba(var(--base-10), 1);
	--sidebar-box: rgba(var(--base-08), 1);
	--sidebar-shadow: rgba(var(--base-12), 0.2);
	--sidebar-border-left: transparent;

	// Footer
	--footer-icon: rgba(var(--base-01), 1);
	--footer-bg: rgba(var(--base-08), 1);
	--footer-box: rgba(var(--primary-accent-grey-light), 1);
	--footer-text: var(--base-01);
	--language-text: rgba(var(--base-01), 1);
	--footer-payment-icon: rgba(var(--font-supportive), 1);
	--footer-payment-logo-bg: rgba(var(--base-01), 1);
	--footer-social-icon: rgba(var(--base-03), 1);
	--footer-18-icon: rgba(var(--error), 1);
	--footer-18-icon-text: rgba(var(--font-light), 1);
	--footer-logo-18-height: 45px;
	--footer-logo-18-width: 45px;
	--footer-link-continue-icon-color: rgba(var(--base-01), 1);
	--flag-eu: rgba(var(--base-06), 1);
	--flag-eu-dots: rgba(var(--base-01), 1);
	--footer-link-color: rgba(var(--font-light), 1);

	// Odds format toggle
	--odds-format-toggle-bg: rgba(var(--primary-accent-grey-light), 1);

	// Hero
	--hero-bg: rgba(var(--base-05), 1);
	--hero-font-color: rgba(var(--base-10), 1);

	// SEO-content 3
	--seo-read-more-btn: var(--primary-accent-red-light);
	--seo-content-border-bottom: rgba(var(--primary-accent-grey-light), 1);
	// --seo-content-bg: defaults to nothing

	// Card
	--box-bg: rgba(var(--base-08), 1);
	--half-circle-bg: rgba(var(--base-10), 1);
	--browse-circle-bg: rgba(var(--base-10), 1);
	--browse-circle-icon: rgba(var(--primary-accent-red-light), 1);
	--open-in-new-window-icon: var(--base-03);

	// Game card
	--bowtie-guy: rgba(var(--base-06), 1);

	// Game info
	--game-info-exit-bg-color: rgba(var(--overlay), 0.8);

	// Loader
	--loading-animation-dots-color: rgba(var(--primary-accent-red), 1);

	//Slider input
	--slider-empty-track: var(--base-05);

	// Bonus welcome package
	--bonus-welcome-package-item-bg: rgba(var(--primary-accent-grey), 1);
	--bonus-welcome-package-item-text-color: rgba(var(--font-light), 1);
	--bonus-welcome-package-nested-collapse-bg: rgba(var(--primary-accent-beige-light), 1);
	--bonus-welcome-package-icon-color: rgba(var(--font-light), 1);
	--bonus-welcome-package-light-text-color: rgba(var(--font-light), 1);
	--bonus-welcome-package-item-read-more-button-box: rgba(var(--primary-accent-grey), 1);
	--bonus-detail-pill-bg: rgba(var(--primary-accent-red-light), 1);

	// Active bonus
	--active-bonus-progress-lane: rgba(var(--base-09), 1);
	--active-bonus-progress-bar: rgba(var(--primary-accent-red), 1);

	// Free to play
	--free-top-play-card-overlay: rgba(var(--opacity-overlay), 0.5);
	--free-to-play-banner-color: rgba(var(--font-light), 1);
	--free-to-play-carousel-arrow-color: rgba(var(--font-light), 1);

	// Virtuals
	--virtuals-root-bg-color: var(--base-09);

	// Highlight-games
	--highlight-games-bg-primary: rgba(var(--base-10), 1);
	--highlight-games-bg-secondary: rgba(var(--base-10), 1);
	--highlight-games-bg-tertiary: rgba(var(--base-11), 1);
	--highlight-games-text-primary: rgba(var(--primary-accent-green), 1); //rgba(var(--base-02), 1);
	//--highlight-games-text-secondary: rgba(var(--base-08), 1); //rgba(var(--base-06), 1);
	--highlight-games-text-secondary: rgba(var(--font-supportive), 1);
	--highlight-games-text-disabled: rgba(var(--font-light), 1); //rgba(var(--base-06), 1);
	--highlight-games-text-secondary-disabled: rgba(var(--base-06), 1); //rgba(var(--base-07), 1);
	--highlight-games-betslip-balance-wrapper-color: rgba(var(--primary-accent-red), 1); //rgba(var(--base-11), 1);
	--highlight-games-betslip-balance-icon-color: rgba(var(--base-01), 1);
	--highlight-games-betslip-header-text-color: var(--font-light);
	--highlight-games-video-player-background: rgba(var(--base-10), 1);
	--highlight-games-mute-icon-color: rgba(var(--primary-accent-red-light), 1);
	--highlight-games-carousel-dices: rgba(var(--base-01), 1);
	--highlight-games-pending-bet-color: rgba(var(--pending), 1);
	--highlight-games-bet-body-bg-color: var(--highlight-games-bg-secondary);
	--highlight-games-bet-betline-bg-color: var(--highlight-games-bg-primary);
	--highlight-games-close-betslip-icon: rgba(var(--base-01), 1);
	--highlight-games-back-link-icon: rgba(var(--base-01), 1);
	--highlight-games-pending-bet-close: rgba(var(--base-01), 1);
	--highlight-games-pending-betslip-border-color: rgba(var(--primary-accent-grey-light), 1);
	--hightlight-games-server-offline: rgba(var(--base-01), 1);
	--highlight-games-event-icon-featured: rgba(var(--base-01), 1);
	//--highlight-games-mobile-betslip-bg: defaults to var(--highlight-games-bg-tertiary);
	--highlight-games-void-bet-color: rgba(var(--base-01), 1);
	--highlight-games-clear-bets-btn-color: rgba(var(--base-01), 1);
	--highlight-games-market-count-text-color: rgba(var(--font-light), 1);
	--highlight-games-bet-tab-label-text-color: rgba(var(--font-supportive), 1);
	--highlight-games-bet-tab-text-color: rgba(var(--font-light), 1);
	--highlight-games-bet-header-bg: rgba(var(--primary-accent-grey-dark), 1);
	--highlight-games-bet-tab-border-color: rgba(var(--base-01), 1);
	// --highlight-games-week-event-border: defaults to 0;
	// --highlight-games-week-event-hover-border: defaults to 0;
	--highlight-games-week-event-label-color: rgba(var(--primary-accent-red-light), 1);
	--highlight-games-week-header-onboarding-title: rgba(var(--primary-accent-red-light), 1);
	--highlight-games-events-bg: rgba(var(--base-09), 1);
	--highlight-games-live-color: rgba(var(--primary-accent-gold), 1);
	--highlight-games-live-text-color: rgba(var(--base-10), 1);
	--highlight-games-selection-btn-bg: rgba(var(--base-09), 1);
	--highlight-games-breadcrumbs-text-color: rgba(var(--font-supportive), 1);
	--highlight-games-breadcrumbs-text-color-hover: rgba(var(--font-light), 1);
	--highlight-games-navigation-button-color: rgba(var(--primary), 1);
	--highlight-games-btn-disabled: rgba(var(--base-08), 1);
	--highlight-games-week-btn-bg: rgba(var(--base-10), 1);
	--highlight-games-week-btn-disabled: rgba(var(--base-03), 1);
	--highlight-games-week-btn-text-disabled: rgba(var(--base-04), 1);
	--highlight-games-playing-now-border: rgba(var(--primary-accent-grey-light), 1);

	// highlight games betslip
	--highlight-games-betslip-animated-border: rgba(var(--primary-accent-red-light), 1);
	--highlight-games-betslip-bg: rgba(var(--base-09), 1);
	--highlight-games-betslip-combo-stake-input: rgba(var(--base-08), 1);
	--highlight-games-betslip-pending-bet-stake-input: rgba(var(--base-10), 1);
	--highlight-games-betslip-header-title-color: rgba(var(--font-light), 1);
	--highlight-games-minimized-betslip-header-title-color: var(--sportsbook-text-main);
	--highlight-games-minimized-betslip-header-bets-count-circle-color: rgba(var(--base-01), 1);
	--highlight-games-betslip-header-bets-count-circle-color: rgba(var(--primary), 1);
	--highlight-games-minimized-betslip-header-bets-count-label-color: rgba(var(--primary), 1);
	--highlight-games-betslip-header-bets-count-label-color: var(--sportsbook-text-main);
	--highlight-games-betslip-header-icon-color: rgba(var(--font-light), 1);
	--highlight-games-minimized-betslip-header-icon-color: rgba(var(--font-light), 1);
	--highlight-games-betslip-border-color: rgba(var(--base-10), 1);
	--highlight-games-betslip-clear-button: rgba(var(--base-10), 1);
	--highlight-games-betslip-button-disabled-bg: rgba(var(--base-08), 1);
	--highlight-games-betslip-button-disabled-text: rgba(var(--base-04), 1);
	--highlight-games-navigation-tab-border-color: rgba(var(--base-07), 1);
	--highlight-games-navigation-tab-color: rgba(var(--font-supportive), 1);
	--highlight-games-navigation-tab-active-color: rgba(var(--primary), 1);

	// highligh games shadow
	--highlight-games-shadow-primary: 0 8px 10px -5px rgb(0 0 0 / 20%), 0 16px 24px 2px rgb(0 0 0 / 14%),
		0 6px 30px 5px rgb(0 0 0 / 12%);

	// highlight games selection button
	--highlight-games-selection-button-color: rgba(var(--base-10), 1);
	--highlight-games-selection-button-color-alternative: rgba(var(--base-08), 1);
	--highlight-games-selection-button-selection-text: rgba(var(--font-light), 1);
	--highlight-games-selection-button-disabled-bg-color: var(--button-disabled-bg);

	// Onboarding
	// --highlight-games-bg-secondary: defaults to rgba(var(--base-11), 1)
	--onboarding-backdrop-bg: rgba(var(--base-11), 0.85);
	--onboarding-item-bg: rgba(var(--base-10), 1);

	// Skeleton placeholders
	--skeleton-placeholder-bg: rgba(var(--base-08), 1);
	--skeleton-placeholder-bg-gradient: linear-gradient(
		-90deg,
		var(--skeleton-placeholder-bg) 0%,
		rgba(var(--base-12), 1) 50%,
		var(--skeleton-placeholder-bg) 100%
	);

	// Shop / Promotions
	--shop-points-balance-bg: rgba(var(--base-10), 1);
	--shop-points-balance-button-bg: rgba(var(--primary-accent-red), 1);
	--shop-points-balance-text-color: rgba(var(--font-light), 1);
	--shop-points-balance-button-text: rgba(var(--font-light), 1);
	--shop-points-title-text-color: rgba(var(--font-supportive), 1);
	--shop-points-faq-button: rgba(var(--primary-accent-red), 1);
	--bonus-item-detail-bg: rgba(var(--primary-accent-grey-dark), 1);
	--bonus-item-foreground-text: rgba(var(--font-light), 1);

	// Filter pills
	--filter-pills-bg: rgba(var(--primary-accent-grey), 1);
	--filter-pills-bg-active: rgba(var(--primary-accent-red), 1);
	--filter-pills-text-color-active: rgba(var(--base-01), 1);
	--filter-pills-text-color: rgba(var(--font-light), 1);

	// Sportsbook
	// Selection Button
	--selection-button-color: rgba(var(--base-08), 1);
	--selection-button-color-alternative: rgba(var(--base-10), 1);
	--selection-button-selection-text: rgba(var(--font-light), 1);
	--selection-button-odds-text: rgba(var(--primary-accent-green), 1);
	--selection-button-active-color: var(--button-primary-bg);
	--selection-button-hover-bg-color: var(--button-primary-bg);
	--selection-button-hover-text-color: var(--button-primary-text);
	--selection-button-active-hover-bg-color: var(--button-primary-bg);
	--selection-button-active-text-color: var(--button-primary-text);
	--selection-button-disabled-bg-color: var(--button-disabled-bg);
	--selection-button-disabled-text-color: var(--button-disabled-text);
	--sportsbook-iframe-overlay-bg-color: rgba(var(--primary-accent-red), 1);
	--sportsbook-iframe-overlay-text-color: rgba(var(--base-02), 1);

	// Cookie Banner
	--cookie-policy-link: rgba(var(--primary-accent-red-light), 1);
	//--policy-version-text: defaults to --language-text
	--cookie-toggle-bg-on: rgba(var(--base-08), 1);
	--cookie-toggle-bg-off: rgba(var(--base-08), 1);
	--cookie-toggle-knob-on: rgba(var(--primary-accent-red), 1);
	--cookie-toggle-knob-off: rgba(var(--primary-accent-red), 1);
	--cookiebanner-wrapper-bg: rgba(var(--base-10), 1);
	--cookie-policy-footer-bg: rgba(var(--base-10), 1);
	--cookie-policy-footer-wrap-divider: var(--base-10);
	--cookie-footer-policy-text: rgba(var(--font-light), 1);
	--cookie-footer-wrap-big-bg: rgba(var(--base-10), 1);
	--footer-wrapper-big-divider: var(--base-10);
	--cookie-footer-wrap-small-bg: rgba(var(--base-01), 1);
	--footer-wrapper-small-divider: var(--base-10);
	--cookie-accept-all-text-big: rgba(var(--font-light), 1);
	--cookie-accept-all-text-small: rgba(var(--base-01), 1);
	--cookie-save-settings-btn-text: rgba(var(--base-01), 1);
	--cookie-banner-text: rgba(var(--font-light), 1);
	--cookie-accept-settings-text: rgba(var(--base-01), 1);
	--cookie-accept-settings-small-text: rgba(var(--base-01), 1);
	--cookie-save-settings-btn-bg: rgba(var(--primary-accent-red), 1);
	--cookie-small-save-settings-btn-bg: rgba(var(--primary-accent-red), 1);
	--cookie-small-save-settings-btn-text: rgba(var(--base-01), 1);
	--cookie-link-supportive: rgba(var(--primary-accent-red-light), 1);
	--cookie-banner-logo: rgba(var(--base-01), 1);

	// StakeInput
	--stake-input-button-bg-color: rgba(var(--primary-accent-red), 1);
	--stake-input-button-disabled-color: rgba(var(--base-07), 1);
	--stake-input-bg-color: rgba(var(--base-08), 1);
	--stake-input-text-color: rgba(var(--base-01), 1);
	--stake-input-icon-color: rgba(var(--base-01), 1);
	--stake-input-icon-disabled-color: rgba(var(--base-08), 1);

	// UI package variables
	// ====================
	--scroll-area-scroll-indicator-color: var(
		--base-10
	); // Intentionally set this as rgb because we need to be able to use different alpha values.

	--floating-element-bg-color: rgba(var(--base-11), 1);
	--floating-element-text-color: rgba(var(--base-01), 1);

	// Lists
	--list-item-background-color: rgba(var(--base-10), 1);

	// Date picker
	--date-picker-background: rgba(var(--base-09), 1);
	--date-picker-outline: rgba(var(--primary-accent-red-light), 1);
	--date-picker-selected: rgba(var(--primary), 1);
	--date-picker-text: rgba(var(--font-light), 1);
	--date-picker-text-supportive: rgba(var(--font-supportive), 1);
	--date-picker-text-on-selected: rgba(var(--font-light), 1);

	// Cards

	--card-bg: rgba(var(--base-08), 1);
	--card-header-caption-font: var(--caption);
	--card-header-caption-font-color: rgba(var(--font-supportive));
	--card-header-title-font: var(--label-bold);
	// --card-header-title-font: defaults to --label-bold;
	--card-header-title-font-color: var(--font-light); // defaults to nothing;
	// --card-media-pill-bg defaults to card-bg
	// --card-media-pill-font defaults to --caption
	// --card-media-pill-border-radius defautls 12px;
	// --card-media-icon-right-bg defaults to --secondary;
	// --card-media-icon-right-fill defaults to --base-02;
	// --card-media-banner-text-bg defaults to --primary;
	// --card-media-banner-text defaults to --base-02;
	--card-body-text-font-color: var(--font-supportive);

	// UI Button dots

	// --button-dot-active-bg: defaults to rgba(var(--primary-accent-green), 1)
	// --button-dot-bg: defaults to var(--button-secondary-bg, rgba(var(--base-08), 1))

	// Modal
	--modal-wrapper-bg: rgba(var(--primary-accent-grey), 1);
	--action-sheet-button-text-color: rgba(var(--base-10), 1);
	--action-sheet-button-bg: rgba(var(--base-03), 1);

	//Stepper (registration wizard)
	--stepper-active: rgba(var(--primary-accent-red), 1);
	--custom-stepper-active-color: rgba(var(--base-01), 1);
	--stepper-active-text: rgba(var(--base-01), 1);
	--stepper-inactive: rgba(var(--primary-accent-grey), 1);
	--linear-stepper-inactive: rgba(var(--base-06), 1);
	//registration
	--custom-show-more-btn-color: rgba(var(--primary-accent-red-light), 1);

	//buttons
	--disabled: rgba(var(--font-supportive), 1);

	// inputs
	--input-bg: rgba(var(--base-03), 1);
	--input-label: rgba(var(--primary-accent-green), 1);

	// Limits
	--limits-notification-icon: var(--primary-accent-red);

	//notification-warning-banner
	--notif-banner-bg: rgba(var(--base-08), 1);
	--notif-banner-icon: rgba(var(--primary-accent-red), 1);

	// Responsible Gaming
	//--time-limit-reached-label-color:
	//--time-limit-reached-timer-color:

	// collapse icon color
	--collapse-view-chevron: rgba(var(--base-01), 1);

	//campaign-registration
	--registration-bg: rgba(var(--base-10), 1);

	//bonus offer
	--reg-bonus-text-active: rgba(var(--base-02), 1);

	// Credit Card
	--credit-card: rgba(var(--base-08), 1);
	--cc-indicator: rgba(var(--base-08), 1);
	--cc-indicator-active: rgba(var(--primary-accent-red), 1);
	--cc-card-add-btn: rgba(var(--base-08), 1);
	--cc-card-add-btn-text: rgba(var(--font-light), 1);
	--opening-at-clock-icon: rgba(var(--base-02), 1);

	// New input colors
	--input-background-color: var(--primary-accent-grey);
	--input-disabled-background-color: var(--primary-accent-grey);
	--input-border-color: var(--primary-accent-grey-light);
	--input-border-hover-color: var(--base-01);
	--input-border-focus-color: var(--base-01);
	--input-border-disabled-color: var(--primary-accent-grey);
	--input-border-error-color: var(--primary-accent-red);
	--input-placeholder-color: var(--font-supportive);
	--input-placeholder-disabled-color: var(--primary-accent-grey-dark);
	--input-font-color: var(--font-light);
	--input-font-focus-color: var(--font-light);
	--input-font-disabled-color: var(--font-light);
	--input-error-color: var(--error);
	--input-helper-text-color: var(--font-light);
	--select-arrow-color: var(--base-01);
	--toggle-group-selected-color: var(--font-brand-01);
	--toggle-group-color: var(--primary-accent-grey-dark);

	//Mentor UI Gaming Habits -- Playing Behavior box --
	--button-selection-days-select-color: rgba(var(--primary-accent-red), 1);
	--button-selection-days-color: rgba(var(--primary-accent-grey), 1);
	--game-type-icon-color: var(--base-01);
	--game-type-label-color: rgba(var(--font-light), 1);
	--your-behaviour-box-chart: rgba(var(--primary-accent-red), 1);
	--comparable-players-box-chart: rgba(var(--primary-accent-grey), 1);
	--box-info-data-player: rgba(var(--primary-accent-grey), 1);
	--playing-behaviour-pills-text: rgba(var(--font-light), 1);
	--playing-behaviour-pills-text-selected: rgba(var(--font-light), 1);
	--playing-behaviour-percentage-text: var(--font-dark);
	--playing-behaviour-description-text: rgba(var(--font-supportive), 1);
	--playing-behaviour-readmore-btn-color: rgba(var(--primary-accent-red-light), 1);
	--vertical-line-others: rgba(var(--primary-accent-gold));

	// Support nav
	--opacity-overlay: var(--base-11);
	--propose-locale-head-bg: var(--primary-accent-grey);
	--propose-locale-border: var(--primary-accent-grey-light);
	--propose-locale-text: var(--font-light);

	// Thunderbite
	--thunderbite-modal-bg: rgba(var(--base-10), 0.8);
	--thunderbite-close: rgba(var(--base-01), 1);
	--marketplace-success-close-icon-color: rgba(var(--base-01), 1);
	--marketplace-success-main-icon: var(--section-head-main-icon);
	--marketplace-progress-bg: rgba(var(--base-09), 1);
	--marketplace-progress-bar-bg: rgba(var(--primary-accent-red), 1);
	--marketplace-points-bg: rgba(var(--primary-accent-red), 1);
	--marketplace-points-text: rgba(var(--font-light), 1);
	--marketplace-sale-bg: rgba(var(--primary-accent-gold), 1);
	--marketplace-sale-text: rgba(var(--font-dark), 1);
	--marketplace-sale-text-bg: rgba(var(--base-01), 0.1);
	--marketplace-info-text: rgba(var(--font-light), 1);
	--marketplace-wagering-text: rgba(var(--font-light), 1);
	--marketplace-card-bg: rgba(var(--primary-accent-grey, 1));
	--marketplace-specials-bg: rgba(var(--primary-accent-grey, 1));
	--marketplace-specials-card-bg: rgba(var(--primary-accent-grey-light, 1));
	--marketplace-chevron: rgba(var(--primary-accent-red, 1));
	--marketplace-chevron-bg: rgba(var(--primary-accent-grey-light, 1));

	// Transaction history
	--filter-pills-bg-th: var(--primary-accent-grey);
	--filtering-pills-bg: var(--primary-accent-red);
	--filtering-pills-disable: var(--primary-accent-grey);
	--filtering-pills-hover: var(--primary-accent-red-light);
	--filtering-pill-font-color: var(--font-light);
	--filtering-pill-font-color-dark: var(--font-dark);

	//Transaction link
	--transaction-link-button-text-color: rgba(var(--font-light), 1);
	--transaction-link-button-border-radius: 3px;

	// Transaction history notification
	--transaction-history-notification-background-color: rgba(var(--primary-accent-grey-dark), 1);
	--transaction-history-notification-icon-color: var(--font-light);

	// Binary Question
	--binary-question-inner-background: rgba(var(--base-08), 1);
	--binary-question-divider-color: rgba(var(--base-07), 1);

	// quick deposit mobile toolbar
	--qd-modal-contrast-text: var(--tertiary);
	--quick-deposit-amount-bottom-border-color: rgba(var(--primary-accent-red), 1);
	--quick-deposit-amount-input-bg: rgba(var(--primary-accent-grey), 1);
	--quick-deposit-methods-collapse-bg: rgba(var(--primary-accent-grey), 1);
	--quick-deposit-modal-bg: rgba(var(--primary-accent-grey-dark), 1);

	// Communication popup
	--communication-popup-background-color: rgba(var(--primary-accent-grey), 1);
	--communication-popup-title-color: rgba(var(--font-light), 1);

	//new-quick-deposit-modal

	--new-qd--modal-header-close-icon-bg: rgba(var(--base-07), 1);
	--new-qd--modal-header-close-icon-color: rgba(var(--base-01), 1);
	--new-qd--change-method-color: rgba(var(--primary-accent-red-light), 1);
	--new-qd--disabled-amount-input-bg: rgba(var(--primary-accent-grey), 1);
	--new-qd--amount-input-bg: rgba(var(--primary-accent-grey), 1);
}
